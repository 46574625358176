body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-toggler{
  background-color:#fff !important;
}

@font-face {
  font-family:coco_regular;
  src: url("assets/tipografia/coco-gothic.regular.ttf");
}

@font-face {
  font-family:coco_bold;
  src: url("assets/tipografia/Coco-Gothic-Small-Caps-Bold.ttf");
}

@font-face {
  font-family:coco_bold_italic;
  src: url("assets/tipografia/Coco-Gothic-Small-Caps-Bold-Italic.ttf");
}

@font-face {
  font-family:helvetica_lt;
  src: url("assets/tipografia/HelveticaNeueLTCom-Lt.ttf");
}

@font-face {
  font-family:helvetica_roman;
  src: url("assets/tipografia/HelveticaNeueLTCom-Roman.ttf");
}

@font-face {
  font-family:libertine_lt;
  src: url("assets/tipografia/LinLibertine_aDRS.ttf");
}

@font-face {
  font-family:libertine_bold;
  src: url("assets/tipografia/LinLibertine_aBS.ttf");
}

@font-face {
  font-family:libertine_italic;
  src: url("assets/tipografia/LinLibertine_aBL.ttf");
}