.footer_container{
    width: 100%;
    background-color: #05487c;
    
}
.footer_content{
    width: 100%;
    margin: auto;
    align-items:center;
    padding:3%;
}
.footer_tittle {
    padding: 0px 0px 15px 0px;
}
.footer_tittle h6{
    color: #ffff;
}
.footer_text_a{
    padding-bottom: 15px;
}
.footer_logo img {
    max-width: 150px;
}

.footer_logo {
    position: relative;
    text-align: center;
    height:100%;
  }

.footer_text1 {
    position: relative;
    border-right: 1px solid #edf4fa;
    border-left:1px solid #edf4fa;
  }


.footer_text1, .footer_text2{
    display: flex;
}
.footer_text{
    display: inline-block;
    margin: auto                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                  ;
}
.footer_text1 p, .footer_text2 p{
    display:inline-block;
    color: #fff;
    text-align: left;
    margin-bottom: 0px;
}

.libro_reclamaciones{
    padding: 15px;
    margin-top: 15px;
    background-color: #f2f2f2f2;
    text-align: center;
}
.libro_reclamaciones img{
    width:100%;
    max-width:150px;

}

/* FOOTER END ................*/
.footer_end{
    width: 100%;
    height: 35px;
    background-color: #07406e;
    color: #fff;
    text-align: center;
    padding-top:6px;
    
}
.footer_end p{
    margin-bottom: 0px;
    margin-top:0px;
    
}

@media screen and (max-width: 912px){
    .footer_content p {
        font-size: 15px;
    }
}
@media screen and (max-width: 867px){
    .footer_content p {
        font-size: 14px;
    }
}
@media screen and (min-width:514px) and (max-width: 767px){
    .footer_content p {
        font-size: 14px;
    }
    .footer_logo img {
        margin-bottom:30px;
        margin-top: 30px;
    }
    .footer_text_ayb {
        width: 100%;
        display: flex;
        justify-content:space-around ;
    }
    .footer_text{
        margin: 0px;
        width: 100%;
    }
    .footer_text_a{
        width: 50%;
    }
    .footer_text_b{
        width: 50%;
    }
    .footer_text1 {
        border-right: 1px solid transparent;
        border-left:1px solid transparent;
        border-top:1px solid #edf4fa;
        border-bottom:1px solid #edf4fa;
        padding-top:30px;
        padding-bottom: 30px;
    }
    .footer_text2{
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 0px;
    }
}
@media screen and (max-width: 513px) {
    .footer_logo img {
        max-width: 100px;
    }
    .footer_content{
        padding:14% 3% 3% 3%;
    }
    .footer_text1 {
        border-right: 1px solid transparent;
        border-left:1px solid transparent;
        border-top:1px solid #edf4fa;
        border-bottom:1px solid #edf4fa;
        padding-top:30px;
        padding-bottom: 30px;
    }
   
   
    .footer_text1, .footer_text2{
        margin-top: 7%;
    }
   

}