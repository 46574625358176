.contenedor_navbar2 {
    width: 100%;
    position:relative;
    z-index: 100;
    padding:  0px 3%;
    background-color: #05487c;
    
}
.contenedor_navbar2 .navbar{
  align-items: center;
}
.navbar-toggler{
    float: right;
}
.navbar-nav li{
  font-family:'coco_bold';
}
.logo_qubit2{
    width: 50%;
}
.logo_qubit2 img{
  width: 50px;
}
.nav-link {
    color: #fff !important;
    transition: color 0.3s;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;
}
  
.nav-link:hover {
    border-bottom: 2px solid #fff;
  }

.rrss_nav i {
    border-bottom:2px solid #fff;
}
#navbarSupportedContent{
    width: 50%;
    
}
#navbarSupportedContent ul{
    width: 80%;
    justify-content:space-between;
}
.contenedor_navbar img {
    max-width:180px;
}
.navbar-light .navbar-nav .nav-link {
    color: #fff;
}



.rrss_nav{
    display: flex;
  }

  /***********************************/
  @media screen and (max-width: 1100px) {
    .logo_qubit{
        width:20%;
    }
    .navbarSupportedContent{
        width: 80%;
   }
    .navbar-nav{
        margin:auto;
   }
  }

  
  @media screen and (max-width: 991px) {
    
    .collapse {
      margin-left: 0px;
      background: #fff;
    }
    .nav-link {
      text-align: center;
      color: #1b4079 !important;
    }
  
    .contenedor_buscar_nav {
      margin: auto;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 575px) {
    
  }
 
  @media screen and (max-width: 450px) {
    .contenedor_navbar img {
        max-width:100px;

    }
  }
  