.container_PP{
    width: 100%;

}
.PP_container_tittle{
    width: 100%;
    background-color: #f2f2f2;
    padding: 3% 10%;
    text-align: center;
}
.PP_container_tittle h1{
    font-family: 'coco_bold';
}
.PP_container_tittle span{
    font-family: 'helvetica_lt';
}
.PP_container_body{
    width: 100%;
    padding-left:15% ;
    padding-right: 15%;
    padding-bottom: 40px;
    margin-top: 40px;
}
.PP_container_body p, .PP_container_body li{
    font-family: 'helvetica_lt';
    text-align:justify;
}
.PP_contents_tittle{
    width: 100%;
    background-color:#05487c;
    padding:10px 15px;
    margin-top: 30px;
    margin-bottom:20px ;
}
.PP_contents_tittle h5{
    color: white;
    font-family: 'helvetica_roman';
    margin-bottom:0px ;
}
.PP_contents_text{
    padding-left:15px ;
}
.PP_contents_text ul {
    padding-left: 15px;
}
.PP_contents_text li{
    color: #212529;
    padding-left: 15px;
}
@media screen and (max-width: 600px){
    .PP_container_body{
        width: 100%;
        padding-left:7% ;
        padding-right:7%;
    }
}