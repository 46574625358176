

.container_lpage p {
    font-size: 1rem;
}
.container_lpage ul {
    font-size: 1rem;
}
.container_header {
    width: 100%;
    height:630px;
    max-height: 800px;
    position: relative;
    background-image: url("../assets/img/home.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    padding: 3% 3% 0px 3%;
    margin-right: 0px;
    margin-left: 0px;
}
.header_tittle{
    display: flex;
    width: 100%;
}
.header_text{
    display:inline-block;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 0px;
    color: #fff;
    text-align: left; 
}
.header_text h1 { 
    font-family:'libertine_lt';
}
.header_text p {
    font-family:'helvetica_lt';
    margin-bottom: 48px;
}
.header-link{
    font-family:'coco_bold';
    color: #fff;
    cursor: pointer;
}
.header-link:hover {
    text-decoration: none;
    color: #fff;
}
.triangle_header{
    width: 100%;
    margin-top: -30px;
    height: 30px;
    position: absolute;
    margin-left: 0px;
    margin-right: 0px;
    background-color: transparent;
}
.triangle_header_one{
    width: 50%;
    height: 0px;
    padding: 0px;
    border-right: 30px solid transparent;
    border-left: 0px solid transparent;
    border-bottom: 30px solid #ffffff;
}
.triangle_header_two{
    width: 50%;
    height: 30px;
    padding: 0px;
    border-right: 0px solid transparent;
    border-left: 30px solid transparent;
    border-bottom: 30px solid #ffffff;
}
.welcome_container{
    width: 100%;
}
.services_container{
   background:#f2f2f2;
}


.triangle_services{
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #fff;
    margin: auto;
}
.container_body_p1{
    width: 100%;
    position: relative;
    background-image: url("../assets/img/img2.png");
    background-size: 100%;
    background-repeat: no-repeat;
    padding:80px 5% 94px 5%;
    margin-right: 0px;
    margin-left: 0px;
}
.body_p1_text{
    width: 100%;
    
}
.body_p1_text h4{
    color: #05487c  !important;
    font-family: 'coco_bold';

}
.body_p1_text p,li{
    color: #00679e ;
    font-family: 'helvetica_lt';
}
.body_p1_text p{
    margin-top: 16px;
}
.body_p1_text ul{
    padding-left: 20px;
    padding-right:40px ;
}
.body_p1_text li{
    padding-left: 30px;
}
.container_body_p2{
    width: 100%;
    padding:32px 5% 94px 5%;
    margin-right: 0px;
    margin-left: 0px;

}
.container_body_p2 h4{
    padding-top: 48px;
}
.body_p2_subtitle{
    padding-top:16px;
    padding-bottom:16px;
}
.services_maps1{
    width:100%;
    padding-top: 32px;
    
}
.services_maps2{
    width: 100%;
    padding-top: 32px;
    padding-bottom: 48px;
}
.services_maps1{
    padding-top: 32px;
    margin-bottom: 48px;
}
.services_maps_ghost{
    display: none;
}
.services_maps_text{
    padding-top: 48px;
    margin-bottom: 16px ;
}
.services_maps_text p{
    margin-top: -16px;
}
.services_maps_text ul{
    list-style-type:circle;
}

.services_maps_text ol{
    font-size: 13px;
    padding-left: 92px;
    color: #05487c;
    margin-bottom: 0px;
}
.services_maps_text spam{
    color: #05487c;
    padding-left: 80px;
    font-size: 13px;
    display:block ;
}

/*-------------------*/
.know_us_container{
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
}
.triangle_know_us{
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #f2f2f2;
    margin: auto;
}
.know_us_one, .know_us_two,.know_us_three{
    margin: auto;
    padding-top:48px;
    padding-left:5%;
    padding-right:2%;
}
.know_us_two{
    margin: auto;
    padding-top:48px;
    padding-left:2%;
    padding-right:5%;
}
.know_us_space{
    padding-left: 0px;
    padding-right: 0px;
}

.know_us_image{
    display: flex;
}
.know_us_one img, .know_us_two img, .know_us_three img  {
    width: 100%;
    height: auto;
}
.know_us_container p,h5,h6,h4{
    color: #05487c  ;
    font-weight: bold;

}
.know_us_tittle{
    padding:80px 6% 0px 6%;
    
}
.know_us_tittle h4{
    font-family: 'coco_bold';
}
.know_us_text h2{
    font-family: 'libertine_lt';
    color: #05487c;
}
.know_us_text{
    width: 100%;
    padding-top:20%;
    align-self: center;
    padding-right:15px;
}
.know_us_text h6{
    font-size: 1.2rem;
    font-family: 'coco_bold';
    margin-bottom:30px ;

}
.know_us_container ul{
    padding-left: 20px;
    padding-right:20px ;
}
.know_us_text li{
    padding-left: 30px;
}
.know_us_image_ghost {
    display: none;
}
/*color half border*/
.know_us_one {
    position: relative;
  }
.know_us_one:before {
    content: "";
    position: absolute;
    width: 70%;        
    height: 4px;
    bottom: 0;
    right: 0%;
    border-bottom: 2px solid #05487c;
    z-index: 95;
}
.know_us_two ,.know_us_three {
    position: relative;
    padding-top:3%;
  }

.know_us_two:before {
    content: "";
    position: absolute;
    width: 70%;        
    height: 4px;
    bottom: 0;
    left: 0%;
    border-bottom: 2px solid #05487c;
    z-index: 95;
}

.triangle_form{
    width: 0;
    height: 0;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    border-top: 30px solid #ffff ;
    margin: auto;
}




@media screen and (max-width: 1208px) {
    .container_header{
        background-repeat: no-repeat;
        background-size: cover;
    }
    .container_body_p1{
        background-repeat: no-repeat;
        background-size: 150%;
    }
}
@media screen and (max-width:800px) {
    .services_maps1, .services_maps2 {
        display: none;
    }
    .services_maps_ghost{
        display:block;
        width: 100%;
        padding-top: 32px;
        padding-bottom: 48px;
    }
    
}
@media screen and (max-width: 1034px) {
    .know_us_image{
    padding-top: 200px;
    
    }
    .know_us_text{
        padding-top:10%;
    }
}
@media screen and (max-width: 719px) {
    .know_us_image_ghost {
        display: block;
        padding-top: 48px;
    }
    .know_us_two .know_us_image{
        display: none;
    }
    .know_us_image{
        padding-top: 48px;
    }
    .know_us_text{
        padding-top:48px;
    }
   .know_us_one img, .know_us_two img, .know_us_three img {
    
   }
}
  
  @media screen and (max-width: 575px) {
    .container_header {
        height:500px;
        
    }
    .header_text{
        text-align: center;
        padding-right:15px;
        
    }
    .container_body_p1{
        height: 500px;
        padding:40px 1% 0px 1%;
    }
    
    .welcome_container {
        height:500px;
        margin-top: 10%;
        margin-bottom:14%;
    }
    .col-ghost{
        display: none;
    }
    .body_p1_text ul{
        padding-right:10px;
    }
    .services_maps1{
        margin-bottom: 28px;
    }
    .services_maps_text spam{
        padding-left: 20px;
        margin-top: 20px;
    }
    .services_maps_text ol{
        padding-left: 32px;
        text-align: justify;
    }
    .services_maps2{
        padding-top: 0px;
        padding-bottom: 28px;
    }
    .header_text{
        text-align: left;   
    }
    .header_text h1{
        font-size:1.9rem;
    }
    .container_body_p1{
        background-position: bottom;
        background-size:cover;
    }
    .body_p1_text li{
        padding-left:5px;
    }
    .container_body_p2{
        padding:32px 1% 54px 1%;
        margin-right: 0px;
        margin-left: 0px;
    
    }
    
    .know_us_one, .know_us_two,.know_us_three{
        padding-left:1%;
        padding-right:1%;
    }
    .know_us_two{
        padding-left:1%;
        padding-right:1%;
    }
    .know_us_text{
        padding-right: 0px;
    }
    .know_us_text ul{
        padding-right: 0px;
    }
    .know_us_text li{
        padding-left:5px;
    }
  }
@media screen and (max-width: 400px) {
    .container_body_p1{
        height: 600px;
        padding:40px 1% 0px 1%;
    }
    
    .welcome_container {
        height:600px;
    }
   
}
@media screen and (max-width: 370px) {
    .container_body_p1{
        height: 650px;
        padding:40px 1% 0px 1%;
    }
    
    .welcome_container {
        height:650px;
    }
   
}
@media screen and (max-width: 340px) {
    .container_body_p1{
        height: 700px;
    }
    
    .welcome_container {
        height:700px;
    }
   
}