/* form de contacto ****************************/

.form_container {
    width:100%;
    background-color: #f2f2f2;
}
.form_content {
    width:100%;
    padding:3%;
    margin: auto;
}
.form_text{
    align-self: center;
}
.form_text p {
    margin-right: 30px;
    color: rgb(97, 97, 97);
}
.form_contact_us textarea{
    width: 100%;
    height: 50px;
    resize:none;
}
.form_contact_us{
    background-color: #fff;
    padding: 30px;
}

@media screen and (max-width: 450px) {
    .form_content{
        padding-top:3% ;
        margin-top: 7%;
    }
    .form_text{
        text-align: justify;
        padding-bottom: 7%;
    }
    .form_text p{
        margin-right: 0px;
    }
}